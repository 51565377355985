import React from 'react'
import Layout from 'components/layout'
import {graphql, Link, navigate} from 'gatsby'
import FeaturedDownload from '../assets/images/featured-download.inline.svg'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import {localizedPaths} from 'utils'

const Intro = () => (
  <div className="my-24 mx-auto max-w-3xl px-6">
    <h1 className="text-center text-orange text-5xl mb-8">Tools</h1>
    <p className="text-center text-olive leading-tight text-2xl sm:text-4xl">
      {`Here you'll find downloads to help build your hub, project, or share
      Inhabit with others. To contribute tools to the collection, `}
      <a href="mailto:hello@inhabit.global" className="underline">
        get in touch
      </a>
      .✉️
    </p>
  </div>
)

const Featured = ({fields, frontmatter}) => (
  <div className="flex mb-4 lg:mb-24">
    <div className="text-orange flex-1 w-1/2 text-center hidden md:block">
      <Link
        to={fields.slug}
        className="leading-none py-24 w-3/4 mx-auto flex flex-col justify-between h-full"
      >
        <FeaturedDownload className="mx-auto" aria-hidden />
        <span className="hidden">Featured download</span>
        <span className="font-display text-outline-orange  block md:text-5xl xl:text-6xl my-4">
          {frontmatter.title}
        </span>
        <span className="font-extended text-outline-orange uppercase block md:text-3xl xl:text-4xl">
          {frontmatter.subtitle}
        </span>
        <Link to={fields.slug} className="btn-orange my-4">
          See details
        </Link>
      </Link>
    </div>

    {/* <p className="font-display text-orange text-4xl">Featured download</p> */}
    <div className=" bg-gray-light px-4 py-16 md:py-28 cursor-pointer md:w-1/2  text-center">
      <FeaturedDownload className="mx-auto mb-10 md:hidden" aria-hidden />
      <GatsbyImage
        onClick={() => navigate(fields.slug)}
        image={getImage(frontmatter.cover)}
        objectFit="contain"
        className="max-h-96"
        alt=""
      />
      <Link
        to={fields.slug}
        className="text-outline-orange leading-none block mt-8 mx-auto md:hidden"
      >
        <span className="font-display block text-4xl mb-6">
          {frontmatter.title}
        </span>
        <span className="font-extended uppercase block text-2xl">
          {frontmatter.subtitle}
        </span>
      </Link>
    </div>
  </div>
)

export default function ToolsPage({pageContext, data}) {
  const page = {
    title: 'Tools',
    id: 'tools',
  }

  // mutate pageContext
  pageContext.lang = 'en'
  pageContext.localizedPaths = localizedPaths

  const allTools = data.tools.nodes
  const featured = allTools[0]
  const tools = allTools.slice(1, allTools.length)

  const seo = {
    title: 'Tools | Inhabit: Instructions for Autonomy',
  }

  return (
    <Layout pageInfo={page} pageContext={pageContext} seo={seo}>
      <Intro />
      <Featured {...featured} />
      <div className="grid grid-cols-2 gap-4 sm:gap-12 max-w-4xl lg:max-w-6xl px-4 sm:px-12 lg:px-32 text-olive">
        {tools.map(({frontmatter, fields}) => {
          const image = getImage(frontmatter.cover)
          return (
            <div
              key={fields.slug}
              className=" bg-gray-light text-orange px-4 pb-4 pt-12 flex flex-col justify-between relative cursor-pointer group"
              onClick={() => navigate(fields.slug)}
            >
              {frontmatter.file_type ? (
                <p className="absolute top-4 right-4 uppercase font-sans text-sm">
                  {frontmatter.file_type}
                </p>
              ) : null}
              <GatsbyImage
                image={image}
                alt=""
                objectFit="contain"
                className="w-3/4 flex-1 mx-auto max-h-72 group-hover:rotate-3"
              />
              <Link to={fields.slug} className="mt-4 text-lg leading-tight">
                {frontmatter.title}
              </Link>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ToolsQuery {
    tools: allMdx(
      filter: {slug: {glob: "en/tools/*"}}
      sort: {fields: frontmatter___order}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          cover {
            childImageSharp {
              gatsbyImageData
            }
          }
          file_type
          title
          subtitle
        }
      }
    }
  }
`
